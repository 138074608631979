import React, {Suspense, lazy} from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import {Redirect, Route, Switch} from 'react-router-dom'
import {RootState} from '../../setup'
import {FallbackView} from '../../_metronic/partials'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {AdminRoutes} from './AdminRoutes'

export function PrivateRoutes() {
  const AZEWrapper = lazy(() => import('../pages/arbeitszeiterfassung/AZEWrapper'))
  const VeranstaltungenWrapper = lazy(
    () => import('../pages/veranstaltungen/VeranstaltungenWrapper')
  )
  const KrankmeldungWrapper = lazy(() => import('../pages/krankmeldung/KrankmeldungWrapper'))

  const ak_admin = (useSelector<RootState>(({auth}) => auth.user?.ak_admin, shallowEqual) as any)
    .length
  const modules: any = useSelector<RootState>(({config}) => config.modules, shallowEqual)

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Redirect from='/auth' to='/dashboard' />
        <Redirect exact from='/' to='/dashboard' />

        <Route path='/dashboard' component={DashboardWrapper} />

        <Route path='/arbeitszeiterfassung' component={AZEWrapper} />

        {modules.veranstaltungen ? (
          <Route path='/veranstaltungen' component={VeranstaltungenWrapper} />
        ) : null}

        {modules.krankmeldung ? (
          <Route path='/krankmeldung' component={KrankmeldungWrapper} />
        ) : null}

        {!ak_admin ? <Redirect to='/error/404' /> : <AdminRoutes />}

        <Redirect to='/error/404' />
      </Switch>
    </Suspense>
  )
}
