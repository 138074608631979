import React, {Suspense, lazy} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {FallbackView} from '../../_metronic/partials'

export function AdminRoutes() {
  const AdminAZEWrapper = lazy(() => import('../pages/admin_arbeitszeiterfassung/Admin_AZEWrapper'))
  const TestWrapper = lazy(() => import('../pages/TestPage'))

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path='/admin/arbeitszeiterfassung' component={AdminAZEWrapper} />
        <Route path='/admin/testpage' component={TestWrapper} />

        <Redirect to='/error/404' />
      </Switch>
    </Suspense>
  )
}
