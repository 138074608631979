import React, {useState} from 'react'
import * as Yup from 'yup'
import {Link, Redirect} from 'react-router-dom'
import {useFormik} from 'formik'
import {resetPassword} from '../redux/AuthCRUD'
import {successSwal} from '../../../pages/_components/_customSwal'
import {useIntl} from 'react-intl'

const initialValues = {
  pw1: '',
  pw2: '',
}

// TODO beide gleich
const newPasswordSchema = Yup.object().shape({
  pw1: Yup.string().required('Password is required'),
  pw2: Yup.string().oneOf([Yup.ref('pw1'), null], 'Passwords must match'),
})

export function SetNewPassword() {
  const intl = useIntl()

  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const formik = useFormik({
    initialValues,
    validationSchema: newPasswordSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setHasErrors(undefined)
      resetPassword(values.pw1)
        .then(() =>
          successSwal(intl.formatMessage({id: 'AUTH.RESET.SUCCESS'})).then(() =>
            setHasErrors(false)
          )
        )
        .catch(() => setHasErrors(true))
    },
  })

  return (
    <>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={formik.handleSubmit}
      >
        <div className='text-center mb-10'>
          {/* begin::Title */}
          <h1 className='text-dark mb-3'>{intl.formatMessage({id: 'AUTH.RESET.TITLE'})}</h1>
          {/* end::Title */}

          {/* begin::Link */}
          <div className='text-gray-400 fw-bold fs-4'>
            {intl.formatMessage({id: 'AUTH.RESET.SUBTEXT'})}
          </div>
          {/* end::Link */}
        </div>

        {/* begin::Title */}
        {hasErrors === true && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>
              {intl.formatMessage({id: 'ERROR.ERRORSDETECTED'})}
            </div>
          </div>
        )}

        {hasErrors === false && (
          <>
            <Redirect to='/auth/login' />
          </>
        )}
        {/* end::Title */}

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <label className='form-label fw-bolder text-gray-900 fs-6'>
            {intl.formatMessage({id: 'AUTH.INPUT.PASSWORD'})}
          </label>
          <input
            type='password'
            placeholder=''
            autoComplete='off'
            {...formik.getFieldProps('pw1')}
            className='form-control form-control-lg form-control-solid'
          />
          {formik.touched.pw1 && formik.errors.pw1 && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.pw1}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <label className='form-label fw-bolder text-gray-900 fs-6'>
            {intl.formatMessage({id: 'AUTH.INPUT.CONFIRM_PASSWORD'})}
          </label>
          <input
            type='password'
            placeholder=''
            autoComplete='off'
            {...formik.getFieldProps('pw2')}
            className='form-control form-control-lg form-control-solid'
          />
          {formik.touched.pw2 && formik.errors.pw2 && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.pw2}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
          <button
            type='submit'
            id='kt_password_reset_submit'
            className='btn btn-lg btn-primary fw-bolder me-4'
            disabled={loading ? true : false}
          >
            {!loading && (
              <span className='indicator-label'>
                {intl.formatMessage({id: 'GENERAL.ABSENDEN'})}
              </span>
            )}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                {intl.formatMessage({id: 'GENERAL.WAIT'})}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          <Link to='/auth/login'>
            <button
              type='button'
              id='kt_login_password_reset_form_cancel_button'
              className='btn btn-lg btn-light-primary fw-bolder'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              {intl.formatMessage({id: 'GENERAL.ZURUECK'})}
            </button>
          </Link>{' '}
        </div>
        {/* end::Form group */}
      </form>
    </>
  )
}
