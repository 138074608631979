/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useLayoutEffect, useState} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
// import {Registration} from './components/Registration'
import {ForgotPassword} from './components/ForgotPassword'
import {Login} from './components/Login'

import {PinInput} from './components/PinInput'
import {SetNewPassword} from './components/SetNewPassword'

import * as redux_config from '../../../setup/redux/reducers/Config'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../../setup'
import {toAbsoluteUrl} from '../../../_metronic/helpers'

export function AuthPage() {
  const dispatch = useDispatch()
  // const [refresh, doRefresh] = useState(true)

  const assets: any = useSelector<RootState>(({config}) => config.assets, shallowEqual) as any
  const [logo, setLogo] = useState(undefined)

  useLayoutEffect(() => {
    dispatch(redux_config.actions.init())
  }, [dispatch])

  useEffect(() => {
    document.body.classList.add('bg-white')

    if (assets && assets.logo_text) {
      setLogo(assets.logo_text)
    }

    return () => {
      document.body.classList.remove('bg-white')
    }
  }, [assets, logo])

  return (
    <div
      className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
      // style={{
      //   backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/sketchy-1/14.png')})`,
      // }}
    >
      {/* begin::Content */}
      <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
        {/* begin::Logo */}
        {/* 
          <a href='#' className='mb-12'>
            <img alt='Logo' src={toAbsoluteUrl('/media/logos/logo-1.svg')} className='h-45px' />
            <img alt='Logo' src={logo} className='h-45px' />
          </a> 
        */}
        <img
          src={toAbsoluteUrl(String(logo))}
          alt='logo'
          className='h-125px logo'
          style={{padding: '25px'}}
        ></img>
        {/* end::Logo */}
        {/* begin::Wrapper */}
        <div className='w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto'>
          <Switch>
            <Route path='/auth/login' component={Login} />
            {/* <Route path='/auth/registration' component={Registration} /> */}
            <Route path='/auth/forgot-password' component={ForgotPassword} />
            <Route path='/auth/pin-input' component={PinInput} />
            <Route path='/auth/set-new-password' component={SetNewPassword} />
            <Redirect from='/auth' exact={true} to='/auth/login' />
            <Redirect to='/auth/login' />
          </Switch>
        </div>
        {/* end::Wrapper */}
      </div>
      {/* end::Content */}
      {/* begin::Footer */}
      <div className='d-flex flex-center flex-column-auto p-10'>
        <div className='d-flex align-items-center fw-bold fs-6'>
          {/* <a href='#' className='text-muted text-hover-primary px-2'>
            About
          </a>

          <a href='#' className='text-muted text-hover-primary px-2'>
            Contact
          </a> */}
        </div>
      </div>
      {/* end::Footer */}
    </div>
  )
}
