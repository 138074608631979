import {all} from 'redux-saga/effects'
import {combineReducers} from 'redux'

import * as auth from '../../app/modules/auth'
import * as aks from './reducers/AKs'
import * as config from './reducers/Config'

export const rootReducer = combineReducers({
  auth: auth.reducer,
  arbeitskreise: aks.reducer,
  config: config.reducer,
})

export type RootState = ReturnType<typeof rootReducer>

export function* rootSaga() {
  yield all([auth.saga(), aks.saga(), config.saga()])
}
