import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {put, takeLatest} from 'redux-saga/effects'
import {getAks} from './_CRUD'

import {Array_AK} from './_TYPES'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  Init: '[AKs Init] Action',
  Load: '[AKs Loaded] Action',
}

const initialAKsState: IAKState = {
  list: undefined,
}

export interface IAKState {
  list?: any
}

export const reducer = persistReducer(
  {storage, key: 'v100-demo1-aks', whitelist: ['list']},
  (state: IAKState = initialAKsState, action: ActionWithPayload<IAKState>) => {
    switch (action.type) {
      case actionTypes.Init: {
        return state
      }
      case actionTypes.Load: {
        const arbeitskreise = action.payload?.list
        return {...state, list: arbeitskreise}
      }

      default:
        return state
    }
  }
)

export const actions = {
  init: () => ({type: actionTypes.Init}),
  load: (list: Array_AK) => ({type: actionTypes.Load, payload: {list}}),
}

export function* saga() {
  yield takeLatest(actionTypes.Init, function* initSaga() {
    const {data} = yield getAks()
    yield put(actions.load(data))
  })
}
