import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {put, takeLatest} from 'redux-saga/effects'
import {getConfig} from './_CRUD'

import {Config} from './_TYPES'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  Init: '[Config Init] Action',
  Load: '[Config Loaded] Action',
}

const initialConfigState: IConfigState = {
  assets: {},
  modules: {},
}

export interface IConfigState {
  assets?: any
  modules?: any
}

export const reducer = persistReducer(
  {storage, key: 'v100-demo1-config', whitelist: ['assets', 'modules']},
  (state: IConfigState = initialConfigState, action: ActionWithPayload<IConfigState>) => {
    switch (action.type) {
      case actionTypes.Init: {
        return state
      }
      case actionTypes.Load: {
        const config = action.payload
        return {...state, assets: config?.assets, modules: config?.modules}
      }
      default:
        return state
    }
  }
)

export const actions = {
  init: () => ({type: actionTypes.Init}),
  load: (config: Config) => ({type: actionTypes.Load, payload: config}),
}

export function* saga() {
  yield takeLatest(actionTypes.Init, function* initSaga() {
    const {data} = yield getConfig()
    yield put(actions.load(data))
  })
}
