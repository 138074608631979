import React, {MutableRefObject, useRef, useState} from 'react'
import {Link, Redirect} from 'react-router-dom'
import {checkPin} from '../redux/AuthCRUD'
// import {requestPassword} from '../redux/AuthCRUD'

import {useIntl} from 'react-intl'

export function PinInput() {
  const intl = useIntl()

  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)

  const pins = [
    useRef<HTMLInputElement>() as MutableRefObject<HTMLInputElement>,
    useRef<HTMLInputElement>() as MutableRefObject<HTMLInputElement>,
    useRef<HTMLInputElement>() as MutableRefObject<HTMLInputElement>,
    useRef<HTMLInputElement>() as MutableRefObject<HTMLInputElement>,
    useRef<HTMLInputElement>() as MutableRefObject<HTMLInputElement>,
    useRef<HTMLInputElement>() as MutableRefObject<HTMLInputElement>,
  ]

  const select_prev_input = (key: any) => {
    let index = pins.findIndex((el) => el.current.value === '')

    if (index !== -1) {
      if (key.keyCode === 8) {
        index = index - 1

        if (index >= 0 && index < pins.length) {
          pins[index].current.value = ''
          pins[index].current.focus()
        }
      } else {
        pins[index].current.focus()
      }
    }
  }

  const insertPaste = (paste: any) => {
    let data = paste.clipboardData.getData('Text')

    if (Number(data)) {
      pins.forEach((el, index) => (data[index] ? (el.current.value = data[index]) : ''))
      pins[pins.length - 1].current.focus()
    }
  }

  return (
    <>
      <div
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        id='kt_login_password_reset_form'
      >
        <div className='text-center mb-10'>
          {/* begin::Title */}
          <h1 className='text-dark mb-3'>{intl.formatMessage({id: 'AUTH.PIN.INPUT'})}</h1>
          {/* end::Title */}

          {/* begin::Link */}
          <div className='text-gray-400 fw-bold fs-4'>
            {intl.formatMessage({id: 'AUTH.PIN.QUESTION'})}
          </div>
          {/* end::Link */}
        </div>

        {/* begin::Title */}
        {hasErrors === true && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>
              {intl.formatMessage({id: 'ERROR.ERRORSDETECTED'})}
            </div>
          </div>
        )}

        {hasErrors === false && (
          <>
            <Redirect to='/auth/set-new-password' />
          </>
        )}
        {/* end::Title */}

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <div className='d-flex flex-row justify-content-evenly'>
            <input
              ref={pins[0]}
              className='form-control form-control-lg form-control-solid'
              style={{width: '4em', textAlign: 'center'}}
              type='tel'
              name='pincode-0'
              maxLength={1}
              pattern='[\d]*'
              tabIndex={1}
              placeholder='·'
              autoComplete='off'
              onKeyDown={(key: any) => select_prev_input(key)}
              onPaste={(paste) => insertPaste(paste)}
            />
            <input
              ref={pins[1]}
              className='form-control form-control-lg form-control-solid'
              style={{width: '4em', textAlign: 'center'}}
              type='tel'
              name='pincode-1'
              maxLength={1}
              pattern='[\d]*'
              tabIndex={2}
              placeholder='·'
              autoComplete='off'
              onKeyDown={(key: any) => select_prev_input(key)}
              onPaste={(paste) => insertPaste(paste)}
            />
            <input
              ref={pins[2]}
              className='form-control form-control-lg form-control-solid'
              style={{width: '4em', textAlign: 'center'}}
              type='tel'
              name='pincode-2'
              maxLength={1}
              pattern='[\d]*'
              tabIndex={3}
              placeholder='·'
              autoComplete='off'
              onKeyDown={(key: any) => select_prev_input(key)}
              onPaste={(paste) => insertPaste(paste)}
            />
            <input
              ref={pins[3]}
              className='form-control form-control-lg form-control-solid'
              style={{width: '4em', textAlign: 'center'}}
              type='tel'
              name='pincode-3'
              maxLength={1}
              pattern='[\d]*'
              tabIndex={4}
              placeholder='·'
              autoComplete='off'
              onKeyDown={(key: any) => select_prev_input(key)}
              onPaste={(paste) => insertPaste(paste)}
            />
            <input
              ref={pins[4]}
              className='form-control form-control-lg form-control-solid'
              style={{width: '4em', textAlign: 'center'}}
              type='tel'
              name='pincode-4'
              maxLength={1}
              pattern='[\d]*'
              tabIndex={5}
              placeholder='·'
              autoComplete='off'
              onKeyDown={(key: any) => select_prev_input(key)}
              onPaste={(paste) => insertPaste(paste)}
            />
            <input
              ref={pins[5]}
              className='form-control form-control-lg form-control-solid'
              style={{width: '4em', textAlign: 'center'}}
              type='tel'
              name='pincode-5'
              maxLength={1}
              pattern='[\d]*'
              tabIndex={5}
              placeholder='·'
              autoComplete='off'
              onKeyDown={(key: any) => select_prev_input(key)}
              onPaste={(paste) => insertPaste(paste)}
            />
          </div>
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
          <button
            onClick={() => {
              setLoading(true)
              checkPin(pins.map((el) => el.current.value).join(''))
                .then(() => setHasErrors(false))
                .catch(() => setHasErrors(true))
            }}
            id='kt_password_reset_submit'
            className='btn btn-lg btn-primary fw-bolder me-4'
            disabled={loading ? true : false}
          >
            {!loading && (
              <span className='indicator-label'>
                {intl.formatMessage({id: 'GENERAL.ABSENDEN'})}
              </span>
            )}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                {intl.formatMessage({id: 'GENERAL.WAIT'})}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          <Link to='/auth/login'>
            <button
              type='button'
              id='kt_login_password_reset_form_cancel_button'
              className='btn btn-lg btn-light-primary fw-bolder'
            >
              {intl.formatMessage({id: 'GENERAL.ZURUECK'})}
            </button>
          </Link>{' '}
        </div>
        {/* end::Form group */}
      </div>
    </>
  )
}
