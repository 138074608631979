import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'

const SwalPop = withReactContent(Swal)

export const successSwal = (title: string) => {
  return SwalPop.fire({
    icon: 'success',
    title: title,
    showConfirmButton: false,
    html: (
      <>
        <br></br>
        <button type='button' className='btn btn-sm btn-primary' onClick={() => SwalPop.close()}>
          Schließen
        </button>
      </>
    ),
  })
}

export const errorSwal = (title: string) => {
  return SwalPop.fire({
    icon: 'error',
    title: title,
    showConfirmButton: false,
    html: (
      <>
        <br></br>
        <button type='button' className='btn btn-sm btn-primary' onClick={() => SwalPop.close()}>
          Schließen
        </button>
      </>
    ),
  })
}

export const confirmSwal = (
  title: string,
  fnc_ok: any,
  fnc_cancel = () => SwalPop.close(),
  btn_conf_type = 'primary'
) => {
  return SwalPop.fire({
    icon: 'question',
    title: title,
    showConfirmButton: false,
    html: (
      <>
        <br></br>
        <button
          type='button'
          className={'btn btn-sm me-2 mb-2 btn-' + btn_conf_type}
          onClick={fnc_ok}
        >
          Bestätigen
        </button>
        <button type='button' className='btn btn-sm btn-light me-2 mb-2' onClick={fnc_cancel}>
          Abbrechen
        </button>
      </>
    ),
  })
}

export const closeSwal = () => {
  return SwalPop.close()
}
