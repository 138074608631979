import {AxiosError} from 'axios'

export default function setupAxios(axios: any, store: any) {
  axios.defaults.withCredentials = true

  // axios.interceptors.request.use(
  //   (config: any) => {
  //     const {
  //       auth: { accessToken },
  //     } = store.getState()

  //     if (accessToken) {
  //       config.headers.Authorization = `Bearer ${accessToken}`
  //     }

  //     return config
  //   },
  //   (err: any) => Promise.reject(err)
  // )

  axios.interceptors.response.use(
    (response: any) => {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response
    },
    async (error: AxiosError) => {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error

      switch (error.response?.status) {
        case 403:
          window.location.reload()
          break
        default:
          return error
      }
    }
  )
}
