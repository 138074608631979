import axios from 'axios'
import {Array_AK} from './_TYPES'

export const GET_AKS = `/api/meta/get-aks`
export const GET_CONFIG = `/api/meta/config`

export function getAks() {
  return axios.get<Array_AK>(GET_AKS)
}

export function getConfig() {
  return axios.get(GET_CONFIG)
}
