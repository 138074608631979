/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../../setup'
// import { KTSVG } from '../../../helpers'
// import { AsideMenuItemWithSub } from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'

export function AsideMenuMain() {
  const intl = useIntl()

  const ak_admin: any = useSelector<RootState>(({auth}) => auth.user?.ak_admin, shallowEqual)
  const modules: any = useSelector<RootState>(({config}) => config.modules, shallowEqual)

  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Eltern</span>
        </div>
      </div>

      <AsideMenuItem to='/arbeitszeiterfassung' title='Arbeitszeiterfassung' hasBullet={true} />
      {modules.veranstaltungen ? (
        <AsideMenuItem to='/veranstaltungen' title='Veranstaltungen' hasBullet={true} />
      ) : null}

      {modules.krankmeldung ? (
        <div className='menu-item'>
          <div className='menu-content pt-8 pb-2'>
            <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Kinder</span>
          </div>
        </div>
      ) : null}

      {modules.krankmeldung ? (
        <AsideMenuItem to='/krankmeldung' title='Krankmeldung' hasBullet={true} />
      ) : null}
      {/* <AsideMenuItem to='/testpage' title='TestPage' hasBullet={true} /> */}

      {(ak_admin as any).length ? (
        <>
          <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Adminbereich</span>
            </div>
          </div>

          {modules.veranstaltungen ? (
            <AsideMenuItem
              to='/admin/arbeitszeiterfassung'
              title='Stunden bestätigen'
              hasBullet={true}
            />
          ) : null}
          <AsideMenuItem to='/admin/testpage' title='TestPage' hasBullet={true} />
        </>
      ) : null}

      {/* <AsideMenuItemWithSub
        to='/apps/chat'
        title='Chat'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/communication/com012.svg'
      >
        <AsideMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
        <AsideMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
        <AsideMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
      </AsideMenuItemWithSub>
      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
      </div>
      <div className='menu-item'>
        <a
          target='_blank'
          className='menu-link'
          href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}
        >
          <span className='menu-icon'>
            <KTSVG path='/media/icons/duotune/general/gen005.svg' className='svg-icon-2' />
          </span>
          <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>
        </a>
      </div> */}
    </>
  )
}
